import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import renderHTML from "react-render-html";
import styled from "styled-components";
import Header from "./../../components/Header";

import { AppContext } from "./../../AppContext";

import { checkData } from "./../../hoc";
import IMAGEMUSEO from "./../../assets/Museo-Thyssen-Bornemisza.jpg";

const AppButton = styled(Button)`
  color: #000000;
  background-color: #ffffff;
  font-family: HelveticaNeue-Regular;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 0;
  border: none;
  box-shadow: 8px 8px 5px #dfdedb;
  &:hover {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
  }
  &:focus {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 8px 8px 5px #dfdedb;
  }
`;

const ContainerW = styled(Container)`
  margin-top: 130px;
  & {
    @media screen and (max-width: 400px) {
      margin-top: 100px;
    }
  }
`;

const Home = () => {
  const { language } = useContext(AppContext);
  const toggleMenu = () => {
    const cb = document.querySelector(".bm-burger-button button");
    cb.click();
  };
  return (
    <React.Fragment>
      <Header />
      <ContainerW>
        <Row>
          <Col>
            <img className="w-100" src={IMAGEMUSEO} alt="" />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-justify mt-3">
              {language && language.descripcion
                ? renderHTML(language.descripcion)
                : ""}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <AppButton
              onClick={toggleMenu}
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              {language ? language.BOTON_INICIO : ""}
            </AppButton>
          </Col>
        </Row>
      </ContainerW>
    </React.Fragment>
  );
};

export default checkData(Home);
