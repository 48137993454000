import React, { useEffect, useState, useContext } from "react";
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";

import ReactGA from "react-ga";
import { reactLocalStorage } from "reactjs-localstorage";

import { AppContext } from "./AppContext";

import Drawer from "./components/Drawer";
import Home from "./pages/Home";
import Collection from "./pages/Collection";
import Artwork from "./pages/Artwork";
import Lock from "./pages/Lock";
import Questionnaire from "./pages/Questionnaire";
import Languages from "./pages/Languages";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import "./styles/normalize.css";

import { login, getAllData } from "./services";

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);
}

const App = () => {
  const location = useLocation();
  let history = useHistory();
  usePageViews();
  const {
    setLanguages,
    setCollections,
    setSalas,
    setPlantas,
    setSubMenus,
    setQuestionnaire,
    setDoQuestionnaire,
  } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location.pathname !== "/maps") {
      main();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const main = async () => {
    setDoQuestionnaire();
    await initialize();
    await setInterval(() => {
      initialize();
    }, 1000 * 60 * 5);
  };

  const initialize = async () => {
    await doLogin();
    await fetchAll();
  };

  const doLogin = async () => {
    try {
      const {
        data: { token, lock },
      } = await login();

      reactLocalStorage.set("token", token);
      reactLocalStorage.set("lock", lock);
      ReactGA.set({
        userId: token,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAll = async () => {
    try {
      const code = reactLocalStorage.get("code", null);
      const lock = reactLocalStorage.get("lock", false);
      const questionnaireCompleted = reactLocalStorage.get(
        "questionnaireCompleted",
        true
      );
      if (eval(lock)) {
        if (code) {
          const {
            data: {
              collections,
              languages,
              plantas,
              salas,
              submenu,
              cuestionario,
            },
          } = await getAllData(code);

          setLanguages(languages);
          setDoQuestionnaire(questionnaireCompleted);
          setCollections(collections);
          setPlantas(plantas);
          setSalas(salas);
          setSubMenus(submenu);
          setQuestionnaire(cuestionario);
        } else {
          history.push("/lock");
        }
      } else {
        const {
          data: {
            collections,
            languages,
            plantas,
            salas,
            submenu,
            cuestionario,
          },
        } = await getAllData();
        setLanguages(languages);
        setDoQuestionnaire(questionnaireCompleted);
        setCollections(collections);
        setPlantas(plantas);
        setSalas(salas);
        setSubMenus(submenu);
        setQuestionnaire(cuestionario);
      }
    } catch (error) {
      console.log(error);
    }
  };
  usePageViews();
  return (
    <React.Fragment>
      <Drawer />

      <div id="page-wrap">
        <main>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/lock">
              <Lock />
            </Route>
            <Route path="/languages">
              <Languages />
            </Route>
            <Route exact path="/collection/:collectionId">
              <Collection />
            </Route>
            <Route
              exact
              path="/collection/:collectionId/subCollection/:subCollectionId"
            >
              <Collection />
            </Route>
            <Route exact path="/collection/:collectionId/artwork/:artworkId">
              <Artwork />
            </Route>
            <Route exact path="/questionnaire">
              <Questionnaire />
            </Route>
            <Redirect to="/" />
          </Switch>
        </main>
      </div>
    </React.Fragment>
  );
};

export default App;
