import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/CircleLoader";
import ReactGA from "react-ga";
import { AppContext } from "./../../AppContext";
import Header from "./../../components/Header";

import HOMEIMG from "./../../assets/home.jpg";

const AppButton = styled(Button)`
  color: #000000;
  background-color: #ffffff;
  font-family: HelveticaNeue-Regular;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 0;
  border: none;
  box-shadow: 8px 8px 5px #dfdedb;
  &:hover {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
  }
  &:focus {
    outline: 3px solid #3693a4;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 8px 8px 5px #dfdedb;
  }
`;
const ContainerWrapper = styled(Container)`
  margin-bottom: 30px;
`;

const Div = styled.div`
  margin-top: 130px;
  h1 {
    font-family: Copernicus-Bold;
    font-size: 22px;
    text-align: center;
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 110px;
    }
  }
`;

const Languages = () => {
  let history = useHistory();
  const { languages, setLanguage } = useContext(AppContext);

  const selectLanguage = (item) => {
    setLanguage(item);
    runGA(item);
    history.push("/");
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Div>
        <h1>Audioguía</h1>
      </Div>
      <ContainerWrapper>
        <Row>
          <Col>
            <img className="w-100" src={HOMEIMG} alt="" />
          </Col>
        </Row>
        {languages.length < 1 && (
          <Row>
            <Col className="d-flex justify-content-center mt-3">
              <ClipLoader size={100} color={"#439eae"} loading={true} />
            </Col>
          </Row>
        )}
        {languages.length > 0 &&
          languages.map((item) => {
            return (
              <Row key={`lang-${item.id}`} className="mt-4">
                <Col className="d-flex justify-content-center">
                  <AppButton
                    onClick={() => {
                      selectLanguage(item);
                    }}
                  >
                    <span>{item.nombre}</span>&nbsp;
                  </AppButton>
                </Col>
              </Row>
            );
          })}
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default Languages;
